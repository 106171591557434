import PropTypes from 'prop-types';
import React, { useMemo } from "react";

import { Box, Button, Typography } from "@material-ui/core";
import { SectionContainer } from "@product-site-frontend/shared";
import { graphql } from "gatsby";

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import PageBox from "../components/PageBox";
import SEO from "../components/SEO";

BlogProduct.propTypes = {
  data: PropTypes.object,
};

export default function BlogProduct({ data }) {
  const styles = useMemo(() => ({
    wrapperItems: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      justifyContent: 'center',
    },
    item: {
      maxWidth: '465px',
      flex: {
        xs: '0 0 100%',
        sm: '0 0 100%',
        md: '0 0 100%',
        lg: '0 0 32%',
      },
      border: '1px solid #f8f8f8',
      borderRadius: '4px',
      overflow: 'hidden',
      paddingBottom: '20px',
    },
    wrapperImage: {
      marginBottom: '15px',
      height: '240px',
      overflow: 'hidden',
      textAlign: "center",

      "& > img": {
        display: 'inline-block',
      }
    },
    title: {
      fontSize: '18px !important',
      marginBottom: '15px',
      fontWeight: 'normal',
      textAlign: 'center',
      height: '50px',
      overflow: 'hidden',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    description: {
      fontSize: '14px',
      textAlign: 'center',
      height: 'auto',
      overflow: 'hidden',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    link: {
      padding: '5px 15px',
      border: '1px solid #f6863a',
      textDecoration: 'none',
      borderRadius: '4px',
      margin: '30px auto 0',
      display: 'block',
      maxWidth: '100px',
      textAlign: 'center',
      cursor: 'pointer',
    }
  }), []);

  const renderItem = elem => {
    return elem.map(elem => {
      return (
        <Box key={elem.slug} sx={styles.item}>
          <Box sx={{ ...styles.wrapperImage, background: elem.image.localFile.childImageSharp.gatsbyImageData.backgroundColor }}>
            <img alt={elem.title} src={elem.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
          </Box>

          <Typography sx={styles.title} variant="h2">
            {elem?.title?.length > 56 ? `${elem?.title?.slice(0, 56)}...` : elem?.title}
          </Typography>

          {elem?.description?.length > 0 && (
            <Typography sx={styles.description}>
              {elem?.description?.length > 300 ? `${elem?.description?.slice(0, 300)}...` : elem?.description}
            </Typography>
          )}

          <Button component="a" href={`${process.env.GATSBY_ROOT_URL}/blog/${elem.slug}`} sx={styles.link}>Читать</Button>
        </Box>
      )
    })
  }

  return (
    <PageBox>
      <SEO pageData={{
        title: 'Блог лизинговой компании Arenza: актуальные новости и информация',
        description: 'Здесь Вы можете ознакомиться с последними актуальными новостями в области лизинга оборудования, а также изучить бизнес-кейсы, разрабатываемые лизинговой компанией Arenza',
      }} />

      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Блог'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />

      <SectionContainer>
        <Typography sx={{ mb: { xs: 0, md: 0, lg: 0 } }} variant="h1">
          Блог
        </Typography>
      </SectionContainer>

      <SectionContainer>
        <Box sx={styles.wrapperItems}>
          {renderItem(data.articles.nodes)}
        </Box>
      </SectionContainer>
    </PageBox>
  );
}

export const query = graphql`
  query BlogsPageQuery {
    articles: allStrapiArticles(sort: { order: DESC, fields: strapiId }) {
      nodes {
        strapiId
        title
        description
        publishedAt
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
  }
`;
